$(document).ready(function () {
  const FWP = window.FWP || {};
  document.addEventListener('facetwp-refresh', function () {
    // Check if FWP.facets is defined and if the active category is 'programme'
    if (
      FWP.facets &&
      FWP.facets.post_categories &&
      FWP.facets.post_categories.length > 0 &&
      FWP.facets.post_categories[0] === 'programme'
    ) {
      // If the active category is 'programme', display the 'post_tags' facet
      document.querySelector('.facetwp-facet-post_tags').style.visibility =
        'visible';
    } else {
      // If the active category is not 'programme', hide the 'post_tags' facet
      document.querySelector('.facetwp-facet-post_tags').style.visibility =
        'hidden';
    }
  });
});
