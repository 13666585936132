import domReady from '@wordpress/dom-ready';

domReady(() => {
  const programFilters = document.querySelector('#program-filters');
  const filtersToggle = document.querySelector('#program-filters-toggle');

  if (programFilters && filtersToggle) {
    filtersToggle.addEventListener('click', () => {
      if (programFilters.classList.contains('visible')) {
        programFilters.classList.remove('visible');
        filtersToggle.classList.remove('expanded');
        filtersToggle.setAttribute('aria-expanded', 'false');
      } else {
        programFilters.classList.add('visible');
        filtersToggle.classList.add('expanded');
        filtersToggle.setAttribute('aria-expanded', 'true');
      }
    });
  }
});
